import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"

import componentStyles from "./expertise.module.scss"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Accordion from "../components/Accordion/Accordion"
import ExpertiseIllustration from "../assets/images/expertise-illustration.svg"
import BigDataIcon from "../assets/images/big-data.svg"
import NaturalLanguageProcessingIcon from "../assets/images/natural-language-processing.svg"
import ImageRecognitionIcon from "../assets/images/image-recognition.svg"
import ForecastingIcon from "../assets/images/forecasting.svg"
import PresentationForm from "../components/PresentationForm/PresentationForm"

const fields = [
  {
    icon: BigDataIcon,
    name: "big-data",
    textTranslatePath: "expertise.fields.big-data.text",
    titleTranslatePath: "expertise.fields.big-data.title",
  },
  {
    icon: NaturalLanguageProcessingIcon,
    name: "natural-language-processing",
    textTranslatePath: "expertise.fields.natural-language-processing.text",
    titleTranslatePath: "expertise.fields.natural-language-processing.title",
  },
  {
    icon: ImageRecognitionIcon,
    name: "image-recognition",
    textTranslatePath: "expertise.fields.image-recognition.text",
    titleTranslatePath: "expertise.fields.image-recognition.title",
  },
  {
    icon: ForecastingIcon,
    name: "forecasting",
    textTranslatePath: "expertise.fields.forecasting.text",
    titleTranslatePath: "expertise.fields.forecasting.title",
  },
]

const technologies = [
  {
    name: "programming-languages",
    textTranslatePath: "expertise.technologies.list.programming-languages.text",
    titleTranslatePath:
      "expertise.technologies.list.programming-languages.title",
  },
  {
    name: "technology-stack",
    textTranslatePath: "expertise.technologies.list.technology-stack.text",
    titleTranslatePath: "expertise.technologies.list.technology-stack.title",
  },
  {
    name: "artificial-intelligence",
    textTranslatePath:
      "expertise.technologies.list.artificial-intelligence.text",
    titleTranslatePath:
      "expertise.technologies.list.artificial-intelligence.title",
  },
  {
    name: "web-technologies",
    textTranslatePath: "expertise.technologies.list.web-technologies.text",
    titleTranslatePath: "expertise.technologies.list.web-technologies.title",
  },
  {
    name: "aws-cloud",
    textTranslatePath: "expertise.technologies.list.aws-cloud.text",
    titleTranslatePath: "expertise.technologies.list.aws-cloud.title",
  },
  {
    name: "development-tools",
    textTranslatePath: "expertise.technologies.list.development-tools.text",
    titleTranslatePath: "expertise.technologies.list.development-tools.title",
  },
  {
    name: "production-environment",
    textTranslatePath:
      "expertise.technologies.list.production-environment.text",
    titleTranslatePath:
      "expertise.technologies.list.production-environment.title",
  },
  {
    name: "design-tools",
    textTranslatePath: "expertise.technologies.list.design-tools.text",
    titleTranslatePath: "expertise.technologies.list.design-tools.title",
  },
  {
    name: "project-environment",
    textTranslatePath: "expertise.technologies.list.project-environment.text",
    titleTranslatePath: "expertise.technologies.list.project-environment.title",
  },
  {
    name: "testing-tools",
    textTranslatePath: "expertise.technologies.list.testing-tools.text",
    titleTranslatePath: "expertise.technologies.list.testing-tools.title",
  },
]

const Expertise: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )

  const fieldsClasses = classNames(componentStyles.fields, "container")
  const fieldsListClasses = classNames(componentStyles.fieldsList, "grid-2")

  const technologiesClasses = classNames(
    componentStyles.technologies,
    "container"
  )

  const technologiesListClasses = classNames(componentStyles.technologiesList)
  const technologiesTitleClasses = classNames(
    componentStyles.technologiesTitle,
    "large-section-title"
  )
  const technologiesFirst = []
  const technologiesSecond = []

  technologies.forEach(
    ({ name, textTranslatePath, titleTranslatePath }, index) => {
      const element = (
        <li className={componentStyles.technologiesItem} key={name}>
          <Accordion
            className={componentStyles.technologiesItemArticle}
            title={intl.formatMessage({ id: titleTranslatePath })}
            data-name={name}
          >
            <ul
              className={componentStyles.technologiesItemList}
              data-technology={name}
            >
              {intl
                .formatMessage({ id: textTranslatePath })
                .split(", ")
                .map((item, index) => (
                  <li
                    key={index}
                    className={componentStyles.technologiesItemListItem}
                  >
                    {item}
                  </li>
                ))}
            </ul>
          </Accordion>
        </li>
      )
      if (index % 2 === 0) {
        technologiesFirst.push(element)
      } else {
        technologiesSecond.push(element)
      }
    }
  )

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({ id: "expertise.seo.description" })}
        title={intl.formatMessage({ id: "expertise.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <h1 className={componentStyles.bannerTitle}>
            {intl.formatMessage({ id: "expertise.banner.title" })}
          </h1>
          <div className={componentStyles.bannerImage}>
            <img
              src={ExpertiseIllustration}
              alt="Illustration with company's knowledge spheres"
              width="586"
              height="325"
            />
          </div>
          <div>
            <div className={componentStyles.bannerText}>
              {intl.formatMessage({ id: "expertise.banner.text" })}
            </div>
            <PresentationForm/>
          </div>
        </div>
      </section>
      <section className={fieldsClasses}>
        <ul className={fieldsListClasses}>
          {fields.map(
            ({ icon, name, textTranslatePath, titleTranslatePath }) => (
              <li className={componentStyles.fieldsItem} key={name}>
                <img src={icon} alt={`${name} icon`} width="36" height="36" />
                <h4 className={componentStyles.fieldsTitle}>
                  {intl.formatMessage({ id: titleTranslatePath })}
                </h4>
                <p className={componentStyles.fieldsText}>
                  {intl.formatMessage({ id: textTranslatePath })}
                </p>
              </li>
            )
          )}
        </ul>
      </section>
      <section className={technologiesClasses}>
        <h2 className={technologiesTitleClasses}>
          {intl.formatMessage({ id: "expertise.technologies.title" })}
        </h2>
        <div className={"grid-2"}>
          <ul className={technologiesListClasses}>
            {technologiesFirst.map(technology => technology)}
          </ul>
          <ul className={technologiesListClasses}>
            {technologiesSecond.map(technology => technology)}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default Expertise
