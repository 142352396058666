import React from "react"
import componentStyles from "./Accordion.module.scss"
import classNames from "classnames"

interface AccordionType {
  title: string
  children?: any
  className?: string
  titleClassName?: string
  other?: any
}

const Accordion: React.FC<AccordionType> = ({
  title,
  className,
  children,
  titleClassName,
  ...other
}) => {
  const accordionClasses = classNames(componentStyles.accordion, className)
  const accordionTitleClasses = classNames(
    componentStyles.accordionTitle,
    titleClassName
  )

  const Arrow = (
    <svg
      className={componentStyles.accordionArrow}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11"
        cy="11"
        r="11"
        transform="rotate(-90 11 11)"
        fill="#F76425"
      />
      <path
        d="M5 11H16M16 11L12 16M16 11L12 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

  return (
    <details className={accordionClasses} {...other}>
      <summary className={componentStyles.accordionSummary}>
        <h4 className={accordionTitleClasses}>{title}</h4>
        {Arrow}
      </summary>
      {children}
    </details>
  )
}

export default Accordion
